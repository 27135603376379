<!--  -->
<template>
 <div class="box" @mouseleave="goOut">
     <div class="hot_list">
        <div class="newClass_top">
                <ul>
                    <li v-for="(item, index) in gameshows" :key="index" @mouseover="t &&goHotlist(item.game_name,item.game_id)" @mouseleave="outHotlist"> {{ item.game_name }}</li>
                </ul>
        </div>
        <div class="newClass_bottom" @mouseenter="goOut">
                <ul>
                    <li v-for="(item,index) in gametype" :key="index" @mouseenter.stop="modelGoto(item.type,item.title)" @click="goPath(index)">
                        <img :src="item.img" alt="">
                        <span>{{item.title}}</span>
                    </li>
                </ul>
        </div>
     </div>
     <div v-if="isShow">
        <div class="hot_right" v-if="list" @mouseleave="isShow = true">
            <div class="hot_t">
                <p>{{area}}:</p>
                <ul>
                    <li v-for="(item, index) in gamearea" :key="index" @click="clickTo(item.id)">{{ item.game_name }}</li>
                    <li>全区</li>
                </ul>
            </div>
            <div class="right_bottom">
                <ul>
                    <li v-for="(item, index) in gamearea3" :key="index" @click="clickTo3(item.game_name)">{{ item.game_name }}</li>
                    <li @click="onClick('')">全服</li>
                </ul>
            </div>
        </div>
        <div class="hot_right" v-else>
            <div class="right_top">
                <p>{{ area }}</p>
                <div class="top_r" @click="toMore">
                    更多游戏
                    <i class="el-icon-caret-right"></i>
                </div>
            </div>
            <div class="right_bottom">
                <ul>
                    <li v-for="(item, index) in gametypelist" :key="index" @click="onClick(item.game_id)">{{ item.game_name }}</li>
                </ul>
            </div>
        </div>
     </div>
 </div>
</template>
 
<script>
export default {
 name: 'hotlist',
 data () {
 return {
    t: true,
     ct:null,
     list: true,
     pid: '', //3接口参数
     gameid: "",//2接口参数
     gamearea3: [], //三级列表
     gamearea: [], //二级列表
     gameLists:[],//上面搜索 
     hotlist:[], //热门列表
     gameshows: [], //前八条
     gametypelist: [], //游戏类型数据
     area: "", //当前游戏专区
     first_letter:'',
     game_type:'',
     is_hot: '',
     key_word: '',
     page:1,
     isShow: false,
     gametype: [
            {
            title: "手游专区",
            img: require("../assets/home/syzq1.png"),
            type: 2,
            },
            {
            title: "端游专区",
            img: require("../assets/home/dyzq1.png"),
            type: 1,
            },
            {
            title: "其他游戏",
            img: require("../assets/home/qt1.png"),
            type: 3,
            },
            {
            title: "全部游戏",
            img: require("../assets/home/qb1.png"),
            type: 4,
            },
        ]
    }
  },
  created() {
      this.getMyinfo()
  },
  methods: {
      goOut() {
          this.isShow=false
           window.clearTimeout(this.ct)
      },
    // 热门移入事件
    goHotlist(title, game_id) {
        this.isShow = true
        this.list = true //控制列表
        this.area = title
        this.gameid = game_id
        this.getgameSingle()
    },
    //热门移出
    outHotlist() {
        var that = this
        that.t = false
        that.ct = setTimeout(() => {
            that.t = true
        },17);
       
    },
    // 热门二级点击事件
    clickTo(id) {
        this.pid = id;
        this.getGameArea();
    },
    // 三级
    clickTo3(server) {
         this.server = server
         this.$router.push({
          path: "/lobby",
          query: {
            server: this.server,
            game_id: this.gameid,
            pid:this.pid
          }
        })
        if(this.$route.path == '/lobby') {
          location.reload() 
        }
       
    },
    // 专区移入事件
    modelGoto(type,title){
        if(type == "4") {
            this.isShow = false
            return
        }
        this.isShow = true
        this.list = false//列表
        this.game_type = type
        this.area = title
        this.getMyinfo()
    },
    //   游戏列表接口
    getMyinfo() {
      let user_id = this.$store.state.user_id;
      this.$get("api/game_list", {
        user_id,
        page: this.page,
        game_type: this.game_type,
        first_letter: this.first_letter,
        is_hot: this.is_hot,
      }).then((res) => {
        if(!this.game_type&&!this.is_hot&&!this.first_letter){
          this.gameshows = res.ret.slice(0, 8);
          
        }
        if (this.game_type) {
          this.gametypelist = res.ret;
        } else if (this.is_hot) {
          this.hotlist = res.ret;
        } else {
          this.gameLists = res.ret;
        }
      });
    },
     //联级选择器二级
    getFiltrateList() {
      this.$get("api/game_filtrate_list", {
        game_id: this.gameid,
      }).then((res) => {
        this.gamearea = res.ret.gamearea;
      });
    },
      //联级选择器三级
    getGameArea() {
      this.$get("api/game_area", {
        pid: this.pid,
      }).then((res) => {
        this.gamearea3 = res.ret;
        console.log(this.gamearea3, 12321);
      });
    },
      //左侧单个游戏展示调用
    getgameSingle() {
      this.$get("api/game_filtrate_list", {
        game_id: this.gameid,
      }).then((res) => {
        if (res.code == 0) {
          this.gamearea = res.ret.gamearea;
          if (this.gamearea.length == 0) {
            return this.gamearea3 = []
          } else {
            this.$get("api/game_area", {
              pid: this.gamearea[0].id,
            }).then((res) => {
                this.gamearea3 = res.ret;
            });
          }
        }
      });
    },

    // 跳转租号大厅
      toMore() {
      this.$router.push({
        path: "/lobby"
      });
    },
    onClick(index) {
        if(index != '') {
           this.gameid = index
        }
      this.$router.push({
        path:'/lobby',
        query:{
          game_id:this.gameid,
        }
      });
       if(this.$route.path == '/lobby') {
          location.reload() 
        }
    },
     goPath(index) {
        if(index == 3){
        this.onClick('')
        }
   }

  }
}
</script>
 
<style lang='scss' scoped >
.box{
    width: 930px;
    display: flex;
    justify-content: space-between;
    .hot_list{
        width: 200px;
        height: 430px;
        background-color: #fff;
         box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          border: 1px solid #ebeef5;
        .newClass_top{
            padding-bottom: 10px;
            ul{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                padding: 10px;
                li{
                    width: 75px;
                    height: 30px;
                    background-color: #e1e4eb;
                    border-radius: 8px;
                    text-align: center;
                    line-height: 30px;
                    overflow: hidden;
                    margin-bottom: 10px;
                    color: #83899D;
                    cursor: pointer;
                    &:hover{
                        border: 1px solid #3b7dfd;
                        color: #3b7dfd;
                        background-color: #fff;
                    }
                }
            }
        }
        .newClass_bottom{
            padding-top: 10px;
            ul{
                li{
                    height: 48px;
                    display: flex;
                    align-items: center;
                    padding: 0 16px;
                    cursor: pointer;
                    &:hover{
                        background-color: #3c7efe;
                        span{
                            color: #fff;
                        }
                    }
                    img{
                        width: 28px;
                        height: 28px;
                    }
                    span{
                        font-size: 16px;
                        color: #000;
                        margin-left: 20px;
                    }
                }
            }
        } 
    }
    .hot_right{
            width: 720px;
            min-height: 400px;
            background-color:#fff;
            border-radius: 12px;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            border: 1px solid #ebeef5;
            padding: 0 30px;
            margin-top: 15px;
            .right_top{
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 80px;
                p{
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #000000;
                }
                .top_r{
                    font-size: 13px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #83899D;
                    cursor: pointer;
                }
            }
            .hot_t{
                display: flex;
                align-items: center;
                height: 80px;
                p{                   
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #000000;
                     margin-right: 31px;
                }
                ul{
                    display: flex;
                    align-items: center;
                    li{
                        font-size: 13px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #83899D;
                        margin-right: 25px;
                        position: relative;
                        cursor: pointer;
                        &:hover{
                            color: #000;
                        }
                        &:hover::after{
                               position: absolute;
                                bottom: -3px;
                                left: 50%;
                                margin-left: -3px;
                                display: block;
                                content: "";
                                width: 6px;
                                height: 3px;
                                background: #3c7efe;
                                border-radius: 2px;
                        }
                    }
                }
            }
            .right_bottom{
                ul{
                    display: flex;
                    flex-wrap: wrap;
                    li{
                        width: 25%;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #83899D;
                        margin-bottom: 30px;
                        cursor: pointer;
                        &:hover{
                            color:#3b7dfd;
                        }
                    }
                }
            }
        }
}
</style>